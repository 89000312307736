import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ListSubheader from "@mui/material/ListSubheader";
import MenuIcon from "@mui/icons-material/Menu";

interface NavigationDrawerProps {
  sections: {
    title: string;
    path: string;
    icon: JSX.Element;
  }[];
  buttonColor: string;
  setCurrentPageState(page: string): void;
}

const NavDrawer: React.FC<NavigationDrawerProps> = ({ sections, buttonColor, setCurrentPageState }) => {

  const [state, setState] = React.useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  return (
    <div>
      <React.Fragment key={"left"}>
        <IconButton
          size="large"
          aria-label="Sections"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon sx={{ color: buttonColor}} />
        </IconButton>
        <SwipeableDrawer
          anchor="left"
          open={state}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              <ListItem
                button
                key={`drawer-li-${sections[0].path}`}
                onClick={() => setCurrentPageState(sections[0].path)}
              >
                <ListItemIcon>{sections[0].icon}</ListItemIcon>
                <ListItemText primary={sections[0].title} />
              </ListItem>
              <ListSubheader>APIs Documentation</ListSubheader>
              {sections.slice(1, 4).map((section) => (
                <ListItem
                  button
                  key={`drawer-li-${section.path}`}
                  onClick={() => setCurrentPageState(section.path)}
                >
                  <ListItemIcon>{section.icon}</ListItemIcon>
                  <ListItemText primary={section.title} />
                </ListItem>
              ))}
            </List>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
};

export default NavDrawer;
