import React from "react";
import { RedocStandalone } from "redoc";
import { RedocTheme } from "./RedocTheme";

interface RedocProps {
  spec: string;
}

// Using React memo to avoid unnecesary re-renders
const Redoc = React.memo(function Redoc({ spec }: RedocProps) {
  return (
    <div>
      <RedocStandalone 
        specUrl={spec}  
        options={{
          scrollYOffset: 55,
          theme: RedocTheme
        }}/>
    </div>
  );
});

export default Redoc;
