import { useState, useEffect } from "react";
import MainPage from "./components/MainPage";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

async function fetchJsonConfig() {
  let response = await fetch(
    "config/config.json",
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }
  );
  return await response.json();
}

const LoadingComponent = () => {
  return(
    <Box sx={{ display: "flex", justifyContent: "center"}}>
      <CircularProgress />
    </Box>
  )
};

const App: React.FC = () => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    async function fetchConfig() {
      setConfig(await fetchJsonConfig());
    }
    fetchConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      {config === null ? LoadingComponent() : <MainPage config={config} />}
    </div>
  );
};

export default App;
