import { useState } from "react";
import NavigationBar from "./navbar/NavBar";
import Redoc from "./redoc/Redoc";
import Box from "@mui/material/Box";
import Introduction from "./introduction/Introduction";
import { getLanguageJsonDefinition } from "../services/readLanguageJsonService";
import { getCurrentLang, setCurrentLang } from "../services/localStorageService";
import { getCurrentPage, setCurrentPage } from "../services/localStorageService";

interface MainPageProps {
  config: any
}

const MainPage: React.FC<MainPageProps> = ({config}) => {
  const [pageState, setPageState] = useState<string>(getCurrentPage());
  const [currentLanguage, setCurrentLanguage] = useState(getCurrentLang());
  const [language, setLanguage] = useState(getLanguageJsonDefinition(currentLanguage, config.clientName));
  //const [swaggerJson, setSwaggerJson] = useState<any>(null);

  const setCurrentPageState = (page: string) => {
    setPageState(page);
    setCurrentPage(page);
  };

  const switchLanguage = (lang: string) => {
    setCurrentLang(lang);     // Local storage service
    setCurrentLanguage(lang); // Global language state
    setLanguage(getLanguageJsonDefinition(lang, config.clientName)); // Json with translations
  };
 
  return (
    <div>
      <NavigationBar
        config={config}
        currentPage={pageState}
        setCurrentPageState={setCurrentPageState}
        currentLanguage={currentLanguage}
        switchLanguage={switchLanguage}
      />
      <Box sx={{ marginTop: "50px" }}>
        {pageState === "introduction" ? <Introduction language={language} config={config} />
          : pageState === "as" ? <Redoc spec={config.asSwaggerJsonURL} />
          : pageState === "cms" ? <Redoc spec={config.cmsSwaggerJsonURL} />
          : pageState === "reporting" ? <Redoc spec={config.reportingSwaggerJsonURL}/>
          : null }
      </Box>
    </div>
  );
};

export default MainPage;
