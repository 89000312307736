const language = (clientName: string) => {
    return {
    "introduction.drawer.overview": "Overview",
    "introduction.drawer.terms-used": "Terms used",
    "introduction.drawer.how-to-start": "How to start integration",
    "introduction.drawer.integration-notes": "Integration notes",
    "introduction.drawer.general-guide": "General guide",
    "introduction.drawer.pagination-filter": "Pagination and filter usage",
    "introduction.overview.title": "Overview",
    "introduction.overview.description": `The primary purpose of this document is to communicate the API methods and flows of the ${clientName} integration with Fund Transfer Wallet & Seamless wallet solution so that business and technical implications can be assessed and understood, and so that the design activities can have a clear understanding of the business needs.`,
    "introduction.terms-used.title": "Terms used in this document",
    "introduction.terms-used.transfer-wallet": "Transfer wallet",
    "introduction.terms-used.transfer-wallet.description": `a 3rd party provider of a digital wallet, which transferring funds to/from the ${clientName} system.`,
    "introduction.terms-used.seamless-wallet": "Seamless wallet",
    "introduction.terms-used.seamless-wallet.description": `a 3rd party provider of a digital wallet, which exposes APIs to allow ${clientName} to implement and connect in order to allow for a single wallet across multiple products.`,
    "introduction.terms-used.operator": "Operator",
    "introduction.terms-used.operator.description": `Refers to the entity which is responsible for integrating with the ${clientName} APIs and transfer funds via a digital wallet.`,
    "introduction.terms-used.api-base-url": "API BASE URL",
    "introduction.terms-used.api-base-url.description": "It's the URL for the specific casino on which you're testing and integrating. (consult your implementation manager for the correct URL)",
    "introduction.terms-used.wallet-url": "Wallet URL",
    "introduction.terms-used.wallet-url.description": `This is the URL of the main seamless wallet ${clientName} should integrate with.`,
    "introduction.how-to-start.title": `How to start integration with ${clientName}`,
    "introduction.how-to-start.authorization": "Authorization",
    "introduction.how-to-start.access": "Access",
    "introduction.how-to-start.understand": "Understand",
    "introduction.how-to-start.implement": "Implement and test",
    "introduction.how-to-start.step-1": "Ensure Merchant Information Form (MIF) is correctly filled and delivered to your Client Representative.",
    "introduction.how-to-start.step-2": `Ensure technical skype chat is set up. Please contact your ${clientName} Sales Representative if this is not setup.`,
    "introduction.how-to-start.step-3": "For seamless wallet integration:",
    "introduction.how-to-start.step-3.1": "Ensure wallet URL is provided.",
    "introduction.how-to-start.step-3.2": "Environment will take 2 - 7 days to complete after receiving MIF. However, it might take longer depends on availability.",
    "introduction.how-to-start.step-4": "For transfer wallet integration:",
    "introduction.how-to-start.step-4.1": "Environment preparation & credential usually will take 1 - 2 days to complete after receiving MIF. However, it might take longer depends on availability.",
    "introduction.how-to-start.step-5": "Game list with gameid will be provided together with this documentation, this information is required when launching the games. [Please contact through technical skype chat if you do not have the game list]",
    "introduction.how-to-start.step-6": `The ${clientName} APIs use a token that is retrieved by the ${clientName} Initialization service using the username/password initially assigned to you. This username/password can be created by yourself once you are familiar with the system. [Please contact through technical skype chat if you do not have the credentials]`,
    "introduction.how-to-start.step-7": "Have the correct API URL for testing (staging) environments. [Please contact through technical skype chat if you do not have the URL]",
    "introduction.how-to-start.step-8": `Read this document to understand how to implement the different ${clientName} APIs, please contact through the technical skype chat with any issues.`,
    "introduction.how-to-start.step-9": "Implement the different APIs and test its working as expected.",
    "introduction.integration-notes.title": "Integration Notes",
    "introduction.integration-notes.utc": "UTC+0, except  /API/report/depositswithdrawls (in UTC+8).",
    "introduction.integration-notes.note-1": "All API response date/time is in ",
    "introduction.integration-notes.note-2": "Date/time format in all date fields should conform to ISO 8601. Example 2012-04-23T18:25:43.511Z. For more details, please refer to the link ",
    "introduction.integration-notes.note-2.here": "here.",
    "introduction.integration-notes.note-3": "All API input date/time formats can be time-shifted to the appropriate timezone. Example 2012-04-23+08:00 to denotes UTC+8",
    "introduction.integration-notes.note-4": "For the currency rate that is in 1:1000, our system will automatically perform the conversion. For example: ",
    "introduction.integration-notes.note-4.example": "when the player makes a deposit of 1000 on our deposit API, the player balance will be displayed as 1(K - the symbol represent 1000), this does the same to withdrawal API when the player withdraws 1000, the amount of 1(K - the symbol represent 1000) will be deducted from the player balance.",
    "introduction.integration-notes.note-5": "IDR is the only 1:1000 currency that needs to be converted from the operator side before applying the value to our withdrawal/deposit API. For example: ",
    "introduction.integration-notes.note-5.example": "if the player wants to deposit 1000 on our deposit API, he needs to input 1 instead of 1000 and the player balance will be displayed as 1(K - the symbol represents 1000). This scenario only happens to IDR currency.",
    "introduction.integration-notes.note-6": "The maximum value display from the in-game interface: 21474836.47 (Int 32 maximum value).",
    "introduction.general-guide.title": "General Guide",
    "introduction.general-guide.guide-1": "Token from /api/start/token will have 60mins of validity.",
    "introduction.general-guide.guide-2": " Token from /api/start/tokenwill be needed in header in most of the APIs ( Authorization: {token}).",
    "introduction.general-guide.guide-3": `${clientName} recommended  /api/start/token for every call.`,
    "introduction.general-guide.guide-4": "Some information from /API/start is required for certain APIs (eg. locale in /API/GameLauncher and currency in PUT /API/player).",
    "introduction.general-guide.guide-5": "Here's the sequence on API call:", 
    "introduction.pagination-filter.title": "Pagination and Filter usage for Reports",
    "introduction.pagination-filter.example": "Example:",
    "introduction.pagination-filter.p1": "While it is possible to retrieve the report without pagination and filter, we offer the following optional feature for ease of viewing and retrieving records.",
    "introduction.pagination-filter.p2": "To use the pagination feature, the following parameters are required:",
    "introduction.pagination-filter.p3": "To user filter, the following parameters are required:",
    "introduction.pagination-filter.page-index": "pageIndex",
    "introduction.pagination-filter.page-index.description": "The starting index of the page",
    "introduction.pagination-filter.page-size": "pageSize",
    "introduction.pagination-filter.page-size.description": "Max records display per page",
    "introduction.pagination-filter.order-by": "orderBy",
    "introduction.pagination-filter.order-by.description": "Order using the field. Example: startDate",
    "introduction.pagination-filter.sort-direction": "sortDirection",
    "introduction.pagination-filter.sort-direction.description": "Direction of the sorting. Either ascending(asc) or descending(desc)",
    "introduction.pagination-filter.example-1.1": "When calling the reporting API, one of the returned attribute 'totalCount' will be the total size of the report.",
    "introduction.pagination-filter.example-1.2": "To start from the beginning of the record, the page index will start at 0.",
    "introduction.pagination-filter.example-1.3": "To view the next page, the API is needed to be called again for the next page and by change pageindex to current index +1.",
    "introduction.pagination-filter.field": "field",
    "introduction.pagination-filter.field.description": "The target field to filter",
    "introduction.pagination-filter.filter-text": "filterText",
    "introduction.pagination-filter.filter-text.description": "The text that contains in the target field",
    "introduction.pagination-filter.find-exact": "findExact : Boolean ",
    "introduction.pagination-filter.find-exact.description": " (Optional, Default:false) - Exact filter text or partially similar filter text",
    "introduction.pagination-filter.start-date": "startDate",
    "introduction.pagination-filter.start-date.description": "(Optional) - Filter by startDate",
    "introduction.pagination-filter.end-date": "endDate",
    "introduction.pagination-filter.end-date.description": "(Optional) - Filter by endDate",
    "introduction.pagination-filter.example-2.1": "API returned 100 records but we are interested in certain games like 'Ancient Gods' in the 100 records.",
    "introduction.pagination-filter.example-2.2": "we add filters with 'field': 'gameName', 'filterText': 'Ancient  Gods'.",
    "introduction.pagination-filter.example-2.3": "Result will return the records that contain anything that 'Ancient  Gods' in the 100 records."
    }
}

export default language;