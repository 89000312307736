export const RedocTheme = {
  spacing: {
    unit: 4,
    sectionVertical: 15
  },

  logo: {
    maxWidth: "230px"
  },

  colors: {
    primary: {
      main: "#0645ad",
      light: "rgba(246, 20, 63, 0.42)",
    },
    success: {
      main: "rgba(28, 184, 65, 1)",
      light: "#81ec9a",
      dark: "#083312",
      contrastText: "#000",
    },
    text: {
      primary: "rgba(0, 0, 0, 1)",
      secondary: "#4d4d4d",
    },
    
    http: {
      get: "rgba(0, 200, 219, 1)",
      post: "rgba(28, 184, 65, 1)",
      put: "rgba(255, 187, 0, 1)",
      delete: "rgba(254, 39, 35, 1)",
    },
  },

  typography: {
    fontSize: "16px",
    fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
    optimizeSpeed: true,
    smoothing: "antialiased",
    headings: {
      fontWeight: "bold",
      lineHeight: "1em",
    },
    code: {
      fontWeight: "600",
      color: "rgba(92, 62, 189, 1)",
      wrap: true,
    },
    links: {
      color: "#1a0dab",
      visited: "#660099",
      hover: "#1190f7",
    },
  },

  sidebar: {
    width: "250px",
    textColor: "#000000",
  },

  rightPanel: {
    backgroundColor: "rgba(55, 53, 71, 1)",
    textColor: "#ffffff",
  },
};
