export function setCurrentPage(page: string) {
    localStorage.setItem('currentPage', page);
}

export function getCurrentPage(): string {
    return localStorage.getItem('currentPage') || "introduction";
}

export function setCurrentLang(lang: string) {
    localStorage.setItem('lang', lang);
}

export function getCurrentLang(): string {
    return localStorage.getItem('lang') || "en-US";
}