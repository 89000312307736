import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface DataTableProps {
  columns: {
    title: string;
    align: "inherit" | "left" | "center" | "right" | "justify" | undefined;
  }[];
  rows: {
    field: string;
    dataType: string;
    description: string;
    required: boolean;
  }[];
}

const DataTable: React.FC<DataTableProps> = ({ columns, rows }) => {
  return (
    <TableContainer component={Paper} sx={{ maxWidth: 800}}>
      <Table
        sx={{ maxWidth: 800}}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell 
                key={`table-column-${index}`} 
                align={column.align} 
                sx={{fontWeight: "bold"}}
          >
            {column.title}
          </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={`datatable-row-${index}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {Object.values(row).map((value, rowIndex) => (
                <TableCell
                  key={`datatable-row-${index}-${rowIndex}`}
                  component="th"
                  scope="row"
                  align={typeof value === "boolean" ? "center" : "left"}
                >
                  {typeof value !== "boolean" ? value 
                  : value ? <CheckCircleIcon htmlColor="green" /> 
                  : <CancelIcon htmlColor="red" />}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
