import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import NavigationDrawer from "./NavDrawer";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CasinoIcon from "@mui/icons-material/Casino";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import DescriptionIcon from "@mui/icons-material/Description";
import Divider from "@mui/material/Divider";
import LanguageSelector from "./LanguageSelector";
import NavButton from "./NavButton";
import { Typography } from "@mui/material";

interface NavigationBarProps {
  currentLanguage: string;
  config: any;
  currentPage: string;
  setCurrentPageState(page: string): void;
  switchLanguage(lang: string): void;
}

const NavBar: React.FC<NavigationBarProps> = ({
  config,
  currentLanguage,
  currentPage,
  setCurrentPageState,
  switchLanguage,
}) => {

  const sections = [
    { 
      title: currentLanguage === "en-US" ? "Introduction": "介绍",
      path: "introduction", 
      icon: <MenuBookIcon style={{transform: "scale(0.7)"}}/> 
    },
    { 
      title: currentLanguage === "en-US" ? "Agency System": "代理系统",
      path: "as", 
      icon: <SupportAgentIcon style={{transform: "scale(0.7)"}}/>
    },
    { 
      title:  currentLanguage === "en-US" ? "Reporting Service": "报告服务",
      path: "reporting", 
      icon: <DescriptionIcon style={{transform: "scale(0.7)"}}/>
    },
    { 
      title: currentLanguage === "en-US" ? "CMS": "赌场管理服务",
      path: "cms", 
      icon: <CasinoIcon style={{transform: "scale(0.7)"}}/>
    }
  ];

  const ApiDocumentation = () => { 
    return (
      <Typography
        variant="h1"
        component="div"
        fontSize="16px"
        color={config.navigationSecondaryTextColor}
        sx={{marginLeft: "34px", marginRight: "34px"}}
      >
        API Documentation
      </Typography>
    )
  }
  
  return (
    <AppBar
      position="fixed"
      sx={{backgroundColor: config.navigationPrimaryColor, zIndex: 999}}
    >
      <Container maxWidth="xl" sx={{padding: 0, margin: 0, zIndex: 999}}>
        <Toolbar style={{minHeight: "50px", padding: 0, zIndex: 999}}>
          <Box sx={{ mr: 2, display: { xs: "none", md: "flex" } }}>
            <ApiDocumentation/>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <NavigationDrawer
              sections={sections}
              buttonColor={config.navigationPrimaryTextColor}
              setCurrentPageState={setCurrentPageState}
            />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <ApiDocumentation/>
          </Box>

          <Divider
            orientation="vertical"
            sx={{ backgroundColor: "rgb(138 142 154)", margin: "0.5em" }}
            flexItem
          />
          
          <LanguageSelector
            currentLanguage={currentLanguage}
            switchLanguage={switchLanguage}
            iconColor={config.navigationPrimaryTextColor}
          />
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              position: "fixed",
              right: "0px"
            }}
          >
            {sections.map((section) => (
              <NavButton
                key={`nav-button-${section.path}`}
                section={section}
                currentPage={currentPage}
                textColor={config.navigationPrimaryTextColor}
                hoverColor={config.navigationSecondaryColor}
                setCurrentPageState={setCurrentPageState}
              />
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
