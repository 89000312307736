interface LogoProps {
  source: string;
}

const Logo: React.FC<LogoProps> = ({ source }) => {
  return (
    <img
      style={{ maxWidth: 230, paddingLeft: "5px", paddingTop: "5px"}}
      src={source}
      alt="Logo"
    />
  );
};

export default Logo;
