import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ChineseFlag from "../../assets/images/cny.png";
import UKFlag from "../../assets/images/en.png";

interface LanguageSelectorProps {
  currentLanguage: string;
  switchLanguage(lang: string): void;
  iconColor: string;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  switchLanguage,
  currentLanguage,
  iconColor
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    switchLanguage(event.target.value);
  };

  return (
    <Select
      labelId="select-autowidth-label"
      id="select-autowidth"
      value={currentLanguage}
      onChange={handleChange}
      autoWidth
      label="Language"
      variant="standard"
      disableUnderline
      MenuProps={{
        disableScrollLock: true,
      }}
      sx={{
        marginLeft: "20px",
        "& .MuiSvgIcon-root": {
          color: iconColor,
        },
      }}
    >
      <MenuItem value="en-US">
        <Avatar 
          alt="UK flag" src={UKFlag} 
          sx={{ width: 20, height: 20 }}
        />
      </MenuItem>
      <MenuItem value="zh-CN">
        <Avatar
          alt="Chinese flag"
          src={ChineseFlag}
          sx={{ width: 20, height: 20 }}
        />
      </MenuItem>
    </Select>
  );
};

export default LanguageSelector;
