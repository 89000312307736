import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Logo from "./Logo";
import { Divider } from "@mui/material";

interface IntroductionDrawerProps {
  lang: any;
  logoSource: string;
  hoverColor: string;
  navigate(element: string): void;
}

const IntroductionDrawer: React.FC<IntroductionDrawerProps> = ({
  lang,
  logoSource,
  hoverColor,
  navigate
}) => {

  const sections = [
    { title: lang["introduction.drawer.overview"], ref: "overview" },
    { title: lang["introduction.drawer.terms-used"], ref: "termsUsed" },
    { title: lang["introduction.drawer.how-to-start"], ref: "howToStart" },
    { title: lang["introduction.drawer.integration-notes"], ref: "integrationNotes" },
    { title: lang["introduction.drawer.general-guide"], ref: "generalGuide" },
    { title: lang["introduction.drawer.pagination-filter"], ref: "paginationFilter"}
  ];

  return (
    <div className="introduction-drawer">
      <React.Fragment key={"left"}>
        <Drawer
          anchor="left"
          variant="persistent"
          open={true}
          PaperProps={{
            sx: {
              top: 50,
              zIndex: 1,
              backgroundColor: "#fafafa",
            },
          }}
        >
          <Box sx={{ width: 250 }} role="presentation">
            <List>
              <ListItem sx={{ padding: 0, margin: "auto" }}>
                <div>
                  <Logo source={logoSource} />
                  <Divider sx={{marginTop: "20px", marginLeft: "10px"}}/>
                </div>

                
              </ListItem>
              {sections.map((section) => (
                <ListItem
                  button
                  key={`drawer-li-${section.title}`}
                  onClick={() => navigate(section.ref)}
                  sx={{
                    "&:hover": {
                      color: hoverColor,
                    },
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "14px" }}
                    primary={section.title}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default IntroductionDrawer;
