import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import DataTable from "./DataTable";
import IntroductionDrawer from "./IntroductionDrawer";
import transferWallet from "../../assets/images/transferWallet.png";
import seamlessWallet from "../../assets/images/seamlessWallet.png";
import apiCall from "../../assets/images/apicall.png";
import { useEffect, useRef } from "react";

interface IntroductionProps {
  config: any;
  language: any;
}

const tableColumns = [
  {title: "Parameter", align: "left" as const},
  {title: "Data Type", align: "left" as const},
  {title: "Description", align: "left" as const},
  {title: "Required", align: "center" as const}];

const Introduction: React.FC<IntroductionProps> = ({ language, config }) => {

  const style = {
    margin: "8px",
    padding: 10,
    titleFontSize: "22px",
    subtitleFontSize: "18px",
    titleColor: config.introductionTitleColor,
    subtitleColor: config.introductionTitleColor
  }

  const tablePaginationUsage = [
    {
      field: "pageIndex",
      dataType: "Integer",
      description: language["introduction.pagination-filter.page-index.description"],
      required: true,
    },
    {
      field: "pageSize",
      dataType: "Integer",
      description: language["introduction.pagination-filter.page-size.description"],
      required: true,
    },
    {
      field: "orderBy",
      dataType: "String",
      description: language["introduction.pagination-filter.order-by.description"],
      required: true,
    },{
      field: "sortDirection",
      dataType: "String",
      description: language["introduction.pagination-filter.sort-direction.description"],
      required: true,
    }
  ]

  const tableFilterUsage = [
    {
      field: "field",
      dataType: "String",
      description: language["introduction.pagination-filter.field.description"],
      required: true,
    },
    {
      field: "filterText",
      dataType: "String",
      description: language["introduction.pagination-filter.filter-text.description"],
      required: true,
    },
    {
      field: "findExact",
      dataType: "Boolean",
      description: language["introduction.pagination-filter.find-exact.description"],
      required: false,
    },
    {
      field: "startDate",
      dataType: "Datetime",
      description: language["introduction.pagination-filter.start-date.description"],
      required: false,
    },
    {
      field: "endDate",
      dataType: "Datetime",
      description: language["introduction.pagination-filter.end-date.description"],
      required: false,
    }
  ]

  const overviewSection = useRef<HTMLDivElement>(null);
  const termsUsedSection = useRef<HTMLDivElement>(null);
  const howToStartSection = useRef<HTMLDivElement>(null);
  const integrationNotesSection = useRef<HTMLDivElement>(null);
  const generalGuideSection = useRef<HTMLDivElement>(null);
  const paginationFilterSection = useRef<HTMLDivElement>(null);

  const navigateToElement = (section: string) => {
    let element = section === "overview" ? overviewSection 
      : section === "termsUsed" ? termsUsedSection 
      : section === "howToStart" ? howToStartSection
      : section === "integrationNotes" ? integrationNotesSection
      : section === "generalGuide" ? generalGuideSection
      : paginationFilterSection;

    if(element.current){
      let topOfElement = element.current.offsetTop - 70;
      window.scroll({ top: topOfElement, behavior: "smooth" });
    }
  }

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <IntroductionDrawer 
        lang={language} 
        logoSource={config.brandLogoURL}
        hoverColor={config.introductionTitleColor}
        navigate={navigateToElement} 
      />

      <div 
        className="introduction"
        style={{ 
          paddingTop: "20px",
          paddingLeft: "280px", 
          paddingRight: "20px", 
          fontSize: "14px",
       }}
    >
      {/* Overview */}
      <Typography 
        id="overview"
        variant="h1" 
        component="div" 
        gutterBottom 
        ref={overviewSection}
        style={{ fontSize: style.titleFontSize, marginBottom: "10px", color: style.titleColor}}
      >
        {language["introduction.overview.title"]}
      </Typography>
     
      <p>{language["introduction.overview.description"]}</p>

      <Divider style={{ marginTop: "25px", marginBottom: "25px"}}/>

      {/* Terms Used in this document */}

      <Typography 
        id="termsUsed"
        ref={termsUsedSection}
        variant="h2" 
        component="div" 
        gutterBottom 
        style={{ 
          fontSize: style.subtitleFontSize,
          marginTop: "20px", 
          marginBottom: "20px", 
          color: style.subtitleColor
        }}
      >
        {language["introduction.terms-used.title"]}
      </Typography>
  
      <List disablePadding style={{ marginLeft: "20px" }}>
        <ListItem disablePadding style={{ marginBottom: "20px" }}>
          <Box display="inline-block">
            <span>
              <b>{language["introduction.terms-used.transfer-wallet"] + ": "}</b>
              {language["introduction.terms-used.transfer-wallet.description"]}
            </span>
            <div>
              <img
                src={transferWallet}
                alt="Transfer wallet"
                style={{ width: "70%", height: "auto", marginTop: "20px"}}
              />
            </div>
          </Box>
        </ListItem>

        <ListItem disablePadding style={{ marginBottom: "20px" }}>
          <Box display="inline-block">
            <span style={{ marginBottom: style.margin }}>
              <b>
                {language["introduction.terms-used.seamless-wallet"] + ": "}
              </b>
              {language["introduction.terms-used.seamless-wallet.description"]}
            </span>
            <div>
              <img
                src={seamlessWallet}
                alt="Transfer wallet"
                style={{ width: "70%", height: "auto", marginTop: "20px"}}
              />
            </div>
          </Box>
        </ListItem>

        <ListItem disablePadding style={{ marginBottom: style.margin }}>
          <span>
            <b>{language["introduction.terms-used.operator"] + ": "}</b>
            {language["introduction.terms-used.operator.description"]}
          </span>
        </ListItem>

        <ListItem disablePadding style={{ marginBottom: style.margin }}>
          <span>
            <b>{language["introduction.terms-used.api-base-url"] + ": "}</b>
            {language["introduction.terms-used.api-base-url.description"]}
          </span>
        </ListItem>

        <ListItem disablePadding style={{ marginBottom: style.margin }}>
          <span>
            <b>{language["introduction.terms-used.wallet-url"] + ": "}</b>
            {language["introduction.terms-used.wallet-url.description"]}
          </span>
        </ListItem>
      </List>

      <Divider style={{ marginTop: "30px"}}/>

      {/* How to start integration with RTG */}

      <Typography 
        id="startIntegration"
        ref={howToStartSection}
        variant="h2" 
        component="div" 
        gutterBottom 
        style={{ 
          fontSize: style.subtitleFontSize, 
          marginTop: "30px", 
          marginBottom: "20px",
          color: style.subtitleColor 
        }}
      >
        {language["introduction.how-to-start.title"]}
      </Typography>

      <List style={{ marginLeft: "20px" }}>
        <ListItem disablePadding style={{ marginBottom: style.margin }}>
         <span><b>1. </b>{language["introduction.how-to-start.step-1"]}</span> 
        </ListItem>

        <ListItem disablePadding style={{ marginBottom: style.margin }}>
          <span><b>2. </b>{language["introduction.how-to-start.step-2"]}</span>
        </ListItem>

        <ListItem disablePadding style={{ marginBottom: style.margin }}>
          <span><b>3. </b>{language["introduction.how-to-start.step-3"]}</span>
        </ListItem>

        <ListItem disablePadding style={{ marginBottom: style.margin }}>
          <List disablePadding style={{ marginLeft: "20px" }}>
            <ListItem disablePadding style={{ marginBottom: style.margin }}>
              <span><b>3.1 </b>{language["introduction.how-to-start.step-3.1"]}</span>
            </ListItem>

            <ListItem disablePadding style={{ marginBottom: style.margin }}>
              <span><b>3.2 </b>{language["introduction.how-to-start.step-3.2"]}</span>
            </ListItem>
          </List>
        </ListItem>

        <ListItem disablePadding style={{ marginBottom: style.margin }}>
          <span><b>4. </b> {language["introduction.how-to-start.step-4"]}</span>
        </ListItem>

        <ListItem disablePadding>
          <List disablePadding style={{ marginLeft: "20px" }}>
            <ListItem disablePadding style={{ marginBottom: style.margin }}>
              <span><b>4.1 </b> {language["introduction.how-to-start.step-4.1"]}</span>
            </ListItem>
          </List>
        </ListItem>

        <ListItem disablePadding style={{ marginBottom: style.margin }}>
          <span><b>5. </b> {language["introduction.how-to-start.step-5"]}</span>
        </ListItem>

        <ListItem disablePadding style={{ marginBottom: style.margin }}>
          <span>
            <b>6. {language["introduction.how-to-start.authorization"] + ": "}</b>
            {language["introduction.how-to-start.step-6"]}
          </span>
        </ListItem>

        <ListItem disablePadding style={{ marginBottom: style.margin }}>
          <span>
            <b>7. {language["introduction.how-to-start.access"] + ": "}</b>
            {language["introduction.how-to-start.step-7"]}
          </span>
        </ListItem>

        <ListItem disablePadding style={{ marginBottom: style.margin }}>
          <span>
            <b>8. {language["introduction.how-to-start.understand"] + ": "}</b>
            {language["introduction.how-to-start.step-8"]}
          </span>
        </ListItem>

        <ListItem disablePadding style={{ marginBottom: style.margin }}>
          <span>
            <b>9. {language["introduction.how-to-start.implement"] + ": "}</b>
            {language["introduction.how-to-start.step-9"]}
          </span>
        </ListItem>
      </List>

      <Divider style={{ marginTop: "25px"}}/>

      {/* Integration Notes */}

      <Typography 
        id="integrationNotes"
        ref={integrationNotesSection}
        variant="h2" 
        component="div" 
        gutterBottom 
        style={{ 
          fontSize: style.subtitleFontSize, 
          marginTop: "30px", 
          marginBottom: "20px",
          color: style.subtitleColor 
        }}
      >
         {language["introduction.integration-notes.title"]}
      </Typography>

      <List>
        <ListItem disablePadding style={{ margin: "8px" }}>
          <span>
            {language["introduction.integration-notes.note-1"]}
            <b>{language["introduction.integration-notes.utc"]}</b>
          </span>
        </ListItem>

        <ListItem disablePadding style={{ margin: "8px" }}>
          <span>
            {language["introduction.integration-notes.note-2"]}
            <a href="https://en.wikipedia.org/wiki/ISO_8601">
              {language["introduction.integration-notes.note-2.here"]}
            </a>
          </span>
        </ListItem>

        <ListItem disablePadding style={{ margin: "8px" }}>
          <span>
            {language["introduction.integration-notes.note-4"]}
            <b>{language["introduction.integration-notes.note-4.example"]}</b>
          </span>
        </ListItem>

        <ListItem disablePadding style={{ margin: "8px" }}>
          <span>
            {language["introduction.integration-notes.note-5"]}
            <b>{language["introduction.integration-notes.note-5.example"]}</b>
          </span>
        </ListItem>

        <ListItem disablePadding style={{ margin: "8px" }}>
          {language["introduction.integration-notes.note-6"]}
        </ListItem>
      </List>

      <Divider style={{ marginTop: "25px"}}/>

      {/* General guide */}

      <Typography 
        id="generalGuide"
        ref={generalGuideSection}
        variant="h2" 
        component="div" 
        gutterBottom 
        style={{ 
          fontSize: style.subtitleFontSize, 
          marginTop: "30px", 
          marginBottom: "20px",
          color: style.subtitleColor 
        }}
      >
        {language["introduction.general-guide.title"]}
      </Typography>

      <List disablePadding>
        <ListItem disablePadding style={{ margin: "8px" }}>
          {language["introduction.general-guide.guide-1"]}
        </ListItem>

        <ListItem disablePadding style={{ margin: "8px" }}>
          {language["introduction.general-guide.guide-2"]}
        </ListItem>

        <ListItem disablePadding style={{ margin: "8px" }}>
          {language["introduction.general-guide.guide-3"]}
        </ListItem>

        <ListItem disablePadding style={{ margin: "8px" }}>
          {language["introduction.general-guide.guide-4"]}
        </ListItem>

        <ListItem disablePadding style={{ margin: "8px" }}>
          <Box display="inline-block">
            <span>{language["introduction.general-guide.guide-5"]}</span>
            <div>
              <img
                src={apiCall}
                alt="API Call"
                style={{ width: "85%", height: "auto", marginTop: "20px"}}
              />
            </div>
          </Box>
        </ListItem>
      </List>

      <Divider style={{ marginTop: "25px"}}/>

      {/* Pagination and Filter usage for Reports */}
      
      <Typography 
        id="paginationFilter"
        ref={paginationFilterSection}
        variant="h2" 
        component="div" 
        gutterBottom 
        style={{ 
          fontSize: style.subtitleFontSize, 
          marginTop: "30px", 
          marginBottom: "20px",
          color: style.subtitleColor
        }}
      >
         {language["introduction.pagination-filter.title"]}
      </Typography>

      <p>{language["introduction.pagination-filter.p1"]}</p>

      <p>{language["introduction.pagination-filter.p2"]}</p>
      
      <DataTable columns={tableColumns} rows={tablePaginationUsage} />

      <h4><b>{language["introduction.pagination-filter.example"]}</b></h4>

      <List>
        <ListItem>
          <span>{language["introduction.pagination-filter.example-1.1"]}</span>
        </ListItem>

        <ListItem>
            <span>{language["introduction.pagination-filter.example-1.2"]} </span>
        </ListItem>

        <ListItem>
            <span>{language["introduction.pagination-filter.example-1.3"]}</span>
        </ListItem>
      </List>

      <p>{language["introduction.pagination-filter.p3"]}</p>

      <DataTable columns={tableColumns} rows={tableFilterUsage} />

      <h4><b>{language["introduction.pagination-filter.example"]}</b></h4>

      <List>
        <ListItem>
            <span>{language["introduction.pagination-filter.example-2.1"]}</span>
        </ListItem>

        <ListItem>
          <span>{language["introduction.pagination-filter.example-2.2"]}</span>
        </ListItem>

        <ListItem>
            <span>{language["introduction.pagination-filter.example-2.3"]}</span>
        </ListItem>
      </List>
    </div>
    </div>
    
  );
};

export default Introduction;