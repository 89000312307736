import { Button } from "@mui/material";

interface NavigationBarProps {
  section: {
    title: string;
    path: string;
    icon: React.ReactNode;
  };
  currentPage: string;
  textColor: string;
  hoverColor: string;
  setCurrentPageState(sectionPath: string): void;
}

const NavButton: React.FC<NavigationBarProps> = ({
  section,
  currentPage,
  textColor,
  hoverColor,
  setCurrentPageState,
}) => {

  const buttonStyle = {
    color: textColor,
    fontSize: "11px",
    marginRight: "30px",
    borderRadius: "100px",
    "&:hover": {
      bgcolor: "transparent",
      color: hoverColor,
    },
  }

  const currentButtonStyle = {
    color: hoverColor,
    fontSize: "11px",
    fontWeight: "bold",
    marginRight: "30px",
    borderRadius: "10px"
  }

  return (
    <Button
      key={`button-${section.path}`}
      startIcon={section.icon}
      onClick={() => setCurrentPageState(section.path)}
      variant={ currentPage === section.path ? "outlined" : "text"}
      sx={currentPage === section.path ? currentButtonStyle : buttonStyle}
    > 
    {section.title} 
    </Button>
  );
};

export default NavButton;
