const language = (clientName: string) => {
    return {
    "introduction.drawer.overview": "概述",
    "introduction.drawer.terms-used": "使用的术语",
    "introduction.drawer.how-to-start": "如何开始整合",
    "introduction.drawer.integration-notes": "集成说明",
    "introduction.drawer.general-guide": "概括指南",
    "introduction.drawer.pagination-filter": "分页和过滤器的使用",
    "introduction.overview.title": "概述",
    "introduction.overview.description": `本文挡主要目的为介绍${clientName}的API HTTP 头字段, 流程, 单一钱包(Seamless Wallet)与转账钱包(Transfer Wallet), 以评估业务与技术层面的需求, 并使设计层面符合业务需求。`,
    "introduction.terms-used.title": "使用术语",
    "introduction.terms-used.transfer-wallet": "转账钱包",
    "introduction.terms-used.transfer-wallet.description": `第三方钱包供应商，可将资金转入${clientName}系统或从${clientName}系统转入资金`,
    "introduction.terms-used.seamless-wallet": "单一钱包",
    "introduction.terms-used.seamless-wallet.description": `第三方钱包供应商，公开API以允许${clientName}系统接入和连接, 用户需切换钱包以管理帐户`,
    "introduction.terms-used.operator": "运营商 (Operator)",
    "introduction.terms-used.operator.description": `指负责与${clientName} API 接入并透过钱包系统转账`,
    "introduction.terms-used.api-base-url": "Casino base URL",
    "introduction.terms-used.api-base-url.description": "测试和集成的娱乐场URL。 (请联系您的客户经理以获取相关网址)",
    "introduction.terms-used.wallet-url": "Wallet URL",
    "introduction.terms-used.wallet-url.description": `${clientName}单一钱包集成的URL`,
    "introduction.how-to-start.title": `如何开始和${clientName}接入`,
    "introduction.how-to-start.authorization": "系统认证(Authorization)  ",
    "introduction.how-to-start.access": "接入点 (Access)",
    "introduction.how-to-start.understand": "了解   ",
    "introduction.how-to-start.implement": "接入与测试 (Implement and Test) ",
    "introduction.how-to-start.step-1": "确保商家信息表（MIF）正确填写并交付给您的客户经理",
    "introduction.how-to-start.step-2": `确保Skype群组已被设置。 如果没有设置，请联系您的${clientName}客户经理`,
    "introduction.how-to-start.step-3": "单一钱包集成 :",
    "introduction.how-to-start.step-3.1": "确保已提供钱包URL",
    "introduction.how-to-start.step-3.2": "环境准备和凭证(credential)通常需要2至7天内完成，若超过七天则取决于可用性。",
    "introduction.how-to-start.step-4": "转账钱包集成 :",
    "introduction.how-to-start.step-4.1": "环境准备和凭证通在收到MIF后1至2天内完成。若超过2天则取决于可用性。",
    "introduction.how-to-start.step-5": "带有gameid的游戏列表将与此文档一起提供，启动游戏时将需要此游戏列表 [如果您没有游戏列表，请与您的客户经理Skype联系]",
    "introduction.how-to-start.step-6": `${clientName} API使用由${clientName} 启动服务使用初期分配给您的用户名/密码的符号。这个用户名/密码可以在您熟悉系统后自行修改。[如果您没有凭证，请与您的客户经理Skype联系]`,
    "introduction.how-to-start.step-7": "拥有测试环境的API链接 [如果您没有链接，请透过Skype与客户经理联系]",
    "introduction.how-to-start.step-8": `阅读本文档以了解如何运用不同的${clientName} API，如果有任何问题, 请透过Skype与客户经理联系`,
    "introduction.how-to-start.step-9": "接入不同的API并测试其是否符合预期",
    "introduction.integration-notes.title": "接入注释",
    "introduction.integration-notes.utc": "除了/api/report/depositswithdrawls API 是UTC + 8",
    "introduction.integration-notes.note-1": "所有API回复的日期/时间均为UTC + 0, ",
    "introduction.integration-notes.note-2": "所有日期中的日期/时间格式应符合ISO 8601.示例2012-04-23T18: 25: 43.511Z。 相关详细信息，请参阅此",
    "introduction.integration-notes.note-2.here": "键接",
    "introduction.integration-notes.note-3": "所有API输入的日期/时间可以转换到对应的时区。 示例2012-04-23 + 08:00应显示为UTC + 8",
    "introduction.integration-notes.note-4": "对于1: 1000的货币汇率，我们的系统将自动执行转换。例如: ",
    "introduction.integration-notes.note-4.example": "当玩家在我们的存款API上存入1000时，玩家余额将显示为1（K-符号代表1000），这与玩家提取1000时提取API相同，金额为1（K -符号代表1000）将从玩家余额中扣除",
    "introduction.integration-notes.note-5": "在将转账值应用到我们的提款/存款API之前，IDR是唯一需要从操作员端转换的1: ",
    "introduction.integration-notes.note-5.example": "如果玩家想在我们的存款API上存入1000，他需要输入1而不是1000，玩家余额将显示为1（K-符号代表1000）。这种情况仅发生在IDR货币上",
    "introduction.integration-notes.note-6": "我们游戏系统最高余额只能显示 21474836.47 (Int 32)",
    "introduction.general-guide.title": "概括指南",
    "introduction.general-guide.guide-1": "/api/start/token的令牌将有60分钟的有效期",
    "introduction.general-guide.guide-2": "大多数API中的 HTTP头字段需要 / api / start / token的令牌（系统认证: {token}）",
    "introduction.general-guide.guide-3": `${clientName} 为每次API调用推荐 / api / start / token`,
    "introduction.general-guide.guide-4": "某些API需要来自/ api / start的信息（例如/ api / GameLauncher中的区域设置和PUT / api / player中的货币）",
    "introduction.general-guide.guide-5": "API调用顺序 :",
    "introduction.pagination-filter.title": "报表分页和过滤器",
    "introduction.pagination-filter.example": "示例如下:",
    "introduction.pagination-filter.p1": "在没有报告分页和过滤器的情况下虽然还是可以检索报告，我们提供以下可选功能，便于查看和检索记录。",
    "introduction.pagination-filter.p2": "使用报表分页(pagination feature)功能，将需要以下参数:",
    "introduction.pagination-filter.p3": "用户过滤器 (user filter)，将需要以下参数: ",
    "introduction.pagination-filter.page-index": "pageIndex",
    "introduction.pagination-filter.page-index.description": "页面的起始索引",
    "introduction.pagination-filter.page-size": "pageSize",
    "introduction.pagination-filter.page-size.description": "每页最大记录值",
    "introduction.pagination-filter.order-by": "orderBy : String ",
    "introduction.pagination-filter.order-by.description": "按栏位排序。 例如: 开始日期 (startDate)",
    "introduction.pagination-filter.sort-direction": "sortDirection : String ",
    "introduction.pagination-filter.sort-direction.description": " 排序方向。升序 (ascending) 或降序 (descending)",
    "introduction.pagination-filter.example-1.1": "使用报告API时，返回属性的 totalCount 为报告总页数。",
    "introduction.pagination-filter.example-1.2": "页面索引将从0开始。",
    "introduction.pagination-filter.example-1.3": "若要查看下一页，则需再次使用API，并将pageindex更改为当前页码+1。",
    "introduction.pagination-filter.field": "field: String ",
    "introduction.pagination-filter.field.description": " 用来过滤的字段",
    "introduction.pagination-filter.filter-text": "filterText : String ",
    "introduction.pagination-filter.filter-text.description": "字段中的文字",
    "introduction.pagination-filter.find-exact": "findExact : Boolean ",
    "introduction.pagination-filter.find-exact.description": " (Optional, Default:false) - 精确过滤或部分类似过滤",
    "introduction.pagination-filter.start-date": "startDate : Datetime",
    "introduction.pagination-filter.start-date.description": "(Optional) - 按开始日期过滤",
    "introduction.pagination-filter.end-date": "endDate: Datetime ",
    "introduction.pagination-filter.end-date.description": "(Optional) - 按结束日期过滤",
    "introduction.pagination-filter.example-2.1": "API返回了100条记录，我们只对100条记录中的「远古神兽 (Ancient Gods)」感兴趣。",
    "introduction.pagination-filter.example-2.2": "在过滤器参数中添加 Field，过滤gameName 和 filterText 中包含「远古神兽 (Ancient Gods)」的字段。",
    "introduction.pagination-filter.example-2.3": "过滤结果将返回100条记录中包含「远古神兽 (Ancient Gods)」的记录"
    }
}

export default language;